import React, { useEffect, useState } from "react"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import useForm from "../../../utils/useForm"
import validator from "../../../utils/validation"
import { addTeamMemberRequest, addTeamMemberFailure } from "./redux/action"
import { connect } from "react-redux"
import "./style.scss"
import AesPhoneInput from "../../../components/PhoneInput"
import AeSelect from "../../../components/AeSelect"
import { toast } from "react-hot-toast"

const AddTeamMember = props => {
  const {
    addTeamMemberRequest,
    addTeamMemberFailure,
    teamMember,
    requesting,
    error,
    loginInfo
  } = props
  const [role, setRole] = useState("Operator")
  const stateSchema = {
    fullName: {
      value: "",
      error: ""
    },
    email: {
      value: "",
      error: ""
    },
    phone: {
      value: "",
      error: ""
    },
    location: {
      value: "",
      error: ""
    },
    role: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    fullName: {
      required: true
    },
    email: {
      required: true,
      validator: validator.email
    },
    phone: {
      required: true
    },
    location: {
      required: true
    },
    role: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    if (addTeamMemberRequest) {
      setState(stateSchema)
      addTeamMemberFailure(false)
    }
  }, [addTeamMemberRequest])

  useEffect(() => {
    teamMember && history.push("my-team")
  }, [teamMember])

  const submit = async () => {
    const formData = new FormData()
    formData.append("name", state.fullName.value)
    formData.append("email", state.email.value)
    formData.append("location", state.location.value)
    formData.append("role", role)
    formData.append("phone_number", state.phone.value)
    await addTeamMemberRequest(formData)

    if (!state.fullName.value || !state.email.value || !state.location.value) {
      loginInfo?.user?.user_status &&
        toast.error("Mandatory fields are required")
    }
  }

  const handleFormatChanger = value => {
    if (value.length > 0) {
      handleOnChange("phone", ` +${value}`)
    } else handleOnChange("phone", value)
  }

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        {loginInfo && loginInfo?.user?.role == "Operator" || loginInfo && loginInfo?.user?.role == "Manager" ? (
          <div className="errordiv">
            <p>You are Operator and not permitted</p>
          </div>
        ) :
          <section className="content-section add-team-members-section">
            <div className="add-team-members-content-section">
              <div className="heading-add-team-members">
                <div
                  className="back"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("my-team")}
                >
                  <img src={backIcon} alt="" />
                </div>
                <h1>Add Team Member</h1>
              </div>
              <div className="add-members-form-section">
                <div className="row">
                  <div className="col-md-4 add-member-field">
                    <Input
                      placeholder="Enter full name"
                      showLabel={true}
                      label="Full Name"
                      value={state.fullName.value}
                      showError={true}
                      errorMessage={state.fullName.error || error?.name}
                      onChange={val =>
                        handleOnChange("fullName", val.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-4 add-member-field">
                    <Input
                      placeholder="Enter email"
                      showLabel={true}
                      label="Email"
                      type="email"
                      showError={true}
                      errorMessage={state.email.error || error?.email}
                      value={state.email.value}
                      onChange={val => handleOnChange("email", val.target.value)}
                    />
                  </div>
                  <div className="col-md-4 add-member-field">
                    <div className="input-wraper">
                      <label>Phone</label>

                      <AesPhoneInput
                        label="Phone"
                        placeholder="Enter phone"
                        type="number"
                        showError={true}
                        onChange={handleFormatChanger}
                        errorMessage={error?.phone_number}
                        value={state.phone.value}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 add-member-field">
                    <Input
                      placeholder="Enter location"
                      showLabel={true}
                      label="Location"
                      value={state.location.value}
                      showError={true}
                      errorMessage={state.location.error || error?.location}
                      onChange={val =>
                        handleOnChange("location", val.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-4  add-member-field">
                    {/* <Input
                    placeholder="Enter role"
                    showLabel={true}
                    label="Role"
                    value={state.role.value}
                    showError={true}
                    errorMessage={state.role.error || error?.role}
                    onChange={val => handleOnChange("role", val.target.value)}
                  /> */}
                    <label>Role</label>
                    <AeSelect
                      options={[
                        {
                          value: "Manager",
                          label: "Manager"
                        },
                        {
                          value: "Operator",
                          label: "Operator"
                        }
                      ]}

                      placeholder="Enter role"
                      // value={item.material_name}
                      onChange={item => {
                        setRole(item)
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="button-wrapper">
                    <Button
                      showSpinner={requesting}
                      title="Add Member"
                      className="aes-btn green-btn"
                      onClick={() => submit()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => ({
  addTeamMemberRequest: state.AddTeamMember.addTeamMemberRequest,
  requesting: state.AddTeamMember.requesting,
  error: state.AddTeamMember.error,
  teamMember: state.AddTeamMember.teamMember,
  loginInfo: state.login.loginInfo,
})

const mapDispatchToProps = dispatch => ({
  addTeamMemberRequest: data => dispatch(addTeamMemberRequest(data)),
  addTeamMemberFailure: data => dispatch(addTeamMemberFailure(data))
  // signUpRequestSuccess: data => dispatch(signUpRequestSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddTeamMember)
