// import Login from "./pages/AuthScreens/Login"
import Landing from "./pages/AuthScreens/Landing/index"
import HomeScreen from "./pages/AuthScreens/HomeScreen"
import SignUp from "./pages/AuthScreens/SignUp"
import LoginScreen from "./pages/AuthScreens/LoginScreen"
import ChangePasword from "./pages/AuthScreens/ChangePasword"
import Dashboard from "./pages/Contractor/Dashboard"
import RequestSoilDeposit from "./pages/Contractor/RequestSoilDeposit"
import MyRequests from "./pages/Contractor/MyRequests"

import { Images } from "./theme/Images"
import DepositSite from "./pages/Contractor/DepositSite"
import SoilAuthorizationForm from "./pages/Contractor/SoilAuthorizationForm"
import MyTeam from "./pages/Contractor/MyTeam"
import AddTeamMember from "./pages/Contractor/AddTeamMember"
import TeamMemberProfile from "./pages/Contractor/TeamMemberProfile"
import AccountInformation from "./pages/Contractor/AccountInformation"
import AccountFillSite from "./pages/Contractor/AccountFillSite"
import AccountSingleSummary from "./pages/Contractor/AccountSingleSummary"
import ProjectsList from "./pages/Contractor/ProjectsList"
import AddProjects from "./pages/Contractor/AddProjects"

import ScheduleList from "./pages/Contractor/ScheduleList"
import TruckList from "./pages/Contractor/TruckList"

import AddTruck from "./pages/Contractor/AddTruck"
import AddSchedule from "./pages/Contractor/AddSchedule"

// fillsites
import InvoiceDetails from "./pages/Contractor/InvoiceDetails"
import OwnerMyTeam from "./pages/FillSiteOwner/MyTeam"
import OwnerAddTeamMember from "./pages/FillSiteOwner/AddTeamMember"
import OwnerTeamMemberProfile from "./pages/FillSiteOwner/TeamMemberProfile"
import NotFound from "./pages/NotFound"
import Schedule from "./pages/FillSiteOwner/Schedule"
import ScheduleDetail from "./pages/FillSiteOwner/Schedule/ScheduleDetail"
import FillAccountInformation from "./pages/FillSiteOwner/AccountInformation"
// import InvoiceDetails from "./pages/Constrators/InvoiceDetails"

import Contact from "./pages/VisitingSite/Contact/Contact"
import DepositScreen from "./pages/VisitingSite/DepositScreen"
import Services from "./pages/VisitingSite/Services"
import Home from "./pages/VisitingSite/Home"
import OwnerFillSiteDashbord from "./pages/FillSiteOwner/OwnerFillSiteDashbord"
import RequestList from "./pages/Contractor/RequestList"
import SoilRequest from "./pages/Admin/SoilRequest"
import SoilRequestDetail from "./pages/Admin/SoilRequestDetail"
import SoilRequestConfirmPage from "./pages/Admin/RequestConfirmPage"
import AdminDashboard from "./pages/Admin/AdminDashboard"
import RejectRequestPage from "./pages/Admin/RejectRequestPage"
import NewFileSite from "./pages/Admin/AdminDashboard/NewFileSite"
import NewContractors from "./pages/Admin/AdminContractors/NewContractors"
import EditAndViewUser from "./pages/Admin/EditAndViewUser"
import Invoices from "./pages/Admin/Invoices"

import AdminInvoiceDetails from "./pages/Admin/Invoices/AdminInvoiceDetails"
import DocumentAgreement from "./pages/Admin/DocumentAgreement"
import Reports from "./pages/Admin/Reports"
import ReportDetails from "./pages/Admin/Reports/ReportDetails"
import Notifications from "./pages/Admin/Notifications"
import AdminContractors from "./pages/Admin/AdminContractors"
import AccountRequest from "./pages/Admin/AccountRequest"
import MaterialTracking from "./pages/Admin/MaterialTracking"

import AdminContractorDetail from "./pages/Admin/AdminContractors/AdminContractorDetail"
import ContractorSummaryDetail from "./pages/Admin/AdminContractors/AdminContractorDetail/ContractorSummaryDetail"
export const routes = [
  {
    component: Contact,
    path: "/contact",
    layout: "/visiting"
  },
  {
    component: DepositScreen,
    path: "/soil-deposite-site",
    layout: "/visiting"
  },
  {
    component: Services,
    path: "/services",
    layout: "/visiting"
  },
  {
    component: Home,
    path: "/home",
    layout: "/visiting"
  },

  // {
  //   component: Login,
  //   path: "/login",
  //   layout: "/auth"
  // },

  // dashbord
  {
    name: "Dashboard",
    iconSrc: Images.dashboardIcon,
    activeIconSrc: Images.dashboardActiveIcon,
    component: Dashboard,
    path: "/dashboard",
    layout: "/contractor",
    isShow: "contractor",
    role: "Operator || Manager"
    // isCommon: true,
  },
  {
    name: "Projects",
    iconSrc: Images.requestIcon,
    activeIconSrc: Images.requestActiveIcon,
    component: ProjectsList,
    path: "/projects",
    layout: "/contractor",
    isShow: "contractor",
    role: "Operator || Manager"
  },
  {
    name: "Soil Deposit Request",
    iconSrc: Images.requestIcon,
    activeIconSrc: Images.requestActiveIcon,
    component: RequestSoilDeposit,
    path: "/request-soil-deposit",
    layout: "/contractor",
    isShow: false,
    role: "Manager"
  },
  {
    name: "Soil Deposit Requests",
    iconSrc: Images.myRequestsIcon,
    activeIconSrc: Images.myRequestsActiveIcon,
    component: RequestList,
    path: "/my-requests",
    layout: "/contractor",
    isShow: "contractor",
    role: "Operator || Manager"
  },
  {
    name: "Schedule Soil Deposits",
    iconSrc: Images.requestIcon,
    activeIconSrc: Images.requestActiveIcon,
    component: ScheduleList,
    path: "/schedule",
    layout: "/contractor",
    isShow: "contractor",
    role: "Manager"
  },
  {
    name: "Dispatch Trucks",
    iconSrc: Images.requestIcon,
    activeIconSrc: Images.requestActiveIcon,
    component: TruckList,
    path: "/truck-details",
    layout: "/contractor",
    isShow: "contractor",
    role: "Manager"
  },
  {
    name: "My Requests",
    iconSrc: Images.myRequestsIcon,
    activeIconSrc: Images.myRequestsActiveIcon,
    component: MyRequests,
    path: "/approved-requests",
    layout: "/contractor",
    isShow: false,
    // isOperator:true

    // isCommon: true,
    // isOperator:true

  },
  // My Request Sub Pages
  {
    name: "Deposit Site Screen",
    component: DepositSite,
    path: "/deposit-site",
    layout: "/contractor",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Soil Authorization Form",
    component: SoilAuthorizationForm,
    path: "/soil-form",
    layout: "/contractor",
    isShow: false
    // isCommon: true,
  },
  // Team Members Page
  {
    name: "Manage Team",
    iconSrc: Images.teamMembersIcon,
    activeIconSrc: Images.teamMembersActiveIcon,
    component: MyTeam,
    path: "/my-team",
    layout: "/contractor",
    isShow: "contractor"
    // isCommon: true,
  },
  // Team Members Subpage
  {
    name: "Add Team Member",
    component: AddTeamMember,
    path: "/add-member",
    layout: "/contractor",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Add Projects",
    component: AddProjects,
    path: "/add-projects",
    layout: "/contractor",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Add Schedule",
    component: AddSchedule,
    path: "/add-schedule",
    layout: "/contractor",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Add Truck",
    component: AddTruck,
    path: "/add-truck",
    layout: "/contractor",
    isShow: false
    // isCommon: true,
  },
  
  {
    name: "Team Member Profile",
    component: TeamMemberProfile,
    path: "/member-profile",
    layout: "/contractor",
    isShow: false
    // isCommon: true,
  },
  // Account Overview Page
  {
    name: "Account-Overview",
    iconSrc: Images.accountOverviewIcon,
    activeIconSrc: Images.accountOverviewActiveIcon,
    component: AccountInformation,
    path: "/account_info",
    layout: "/contractor",
    isShow: false,

  },
  // Account Overview Sub Pages
  {
    iconSrc: Images.accountOverviewIcon,
    activeIconSrc: Images.accountOverviewActiveIcon,
    name: "Account Overview",
    component: AccountFillSite,
    path: "/account-overview",
    layout: "/contractor",
    isShow: "contractor",
    // isCommon: true,
  },
  {
    name: "Account Single Summary",
    component: AccountSingleSummary,
    path: "/account-summary",
    layout: "/contractor",
    isShow: false
    // isCommon: true,
  },
  {
    name: "InvoiceDetails",
    component: InvoiceDetails,
    path: "/invoice-Details",
    layout: "/contractor",
    isShow: "/contractor"

    // isCommon: true,
  },
  {
    component: ChangePasword,
    path: "/changePasword",
    layout: "/contractor"
  },

  // fill Site dashbord
  // {
  //   name: "Dashboard",
  //   iconSrc: Images.dashboardIcon,
  //   activeIconSrc: Images.dashboardActiveIcon,
  //   component: OwnerDashbord,
  //   path: "/dashboard",
  //   layout: "/owner",
  //   isShow: false
  //   // isCommon: true,
  // },
  {
    name: "Dashboard",
    iconSrc: Images.dashboardIcon,
    activeIconSrc: Images.dashboardActiveIcon,
    component: OwnerFillSiteDashbord,
    path: "/dashboard",
    layout: "/owner",
    isShow: "owner"

    // isCommon: true,
  },
  {
    name: "Schedule",
    iconSrc: Images.scheduleiconActiveIcon,
    activeIconSrc: Images.scheduleiconIcon,
    component: Schedule,
    path: "/Schedule",
    layout: "/owner",
    isShow: "owner"
    // isCommon: true,
  },
  {
    name: "FillAccountInformation",

    component: FillAccountInformation,
    path: "/Account-information",
    layout: "/owner",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Schedule Detail",
    component: ScheduleDetail,
    path: "/ScheduleDetail",
    layout: "/owner",
    isShow: false
    // isCommon: true,
  },
  // {
  //   name: "Team Members",
  //   iconSrc: Images.teamMembersIcon,
  //   activeIconSrc: Images.teamMembersActiveIcon,
  //   component: OwnerMyTeam,
  //   path: "/my_team",
  //   layout: "/owner",
  //   isShow: "owner"
  //   // isCommon: true,
  // },

  // Team Members Subpage
  {
    name: "Add Team Member",
    component: OwnerAddTeamMember,
    path: "/add-member",
    layout: "/owner",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Team Member Profile",
    component: OwnerTeamMemberProfile,
    path: "/member-profile",
    layout: "/owner",
    isShow: false
    // isCommon: true,
  },
  {
    component: ChangePasword,
    path: "/changePasword",
    layout: "/owner"
  },
  {
    component: NotFound,
    path: "/notfound"

    // isCommon: true,
  },
  {
    name: "Dashboard",
    iconSrc: Images.bellActive,
    activeIconSrc: Images.bell,
    component: Notifications,
    path: "/dashboard",
    layout: "/admin",
    isShow: "admin",
    // role: "Operator || Manager"

    // isCommon: true,
  },
  {
    name: "Soil Deposit Requests",
    iconSrc: Images.requestIcon,
    activeIconSrc: Images.requestActiveIcon,
    component: SoilRequest,
    path: "/soil-request",
    layout: "/admin",
    isShow: "admin",
    role: "Operator || Manager",
  },
  // Admin Routing
  {
    name: "Fill Sites",
    iconSrc: Images.dashboardIcon,
    activeIconSrc: Images.dashboardActiveIcon,
    icon:<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_44_176" fill="white">
    <rect x="12" width="9.99994" height="12.9999" rx="1"/>
    </mask>
    <rect x="12" width="9.99994" height="12.9999" rx="1" stroke="#83CDC8" stroke-width="2.4" mask="url(#path-1-inside-1_44_176)"/>
    <mask id="path-2-inside-2_44_176" fill="white">
    <rect x="12" y="14.9999" width="9.99994" height="7.49996" rx="1"/>
    </mask>
    <rect x="12" y="14.9999" width="9.99994" height="7.49996" rx="1" stroke="#83CDC8" stroke-width="2.4" mask="url(#path-2-inside-2_44_176)"/>
    <mask id="path-3-inside-3_44_176" fill="white">
    <rect width="9.99994" height="7.49996" rx="1"/>
    </mask>
    <rect width="9.99994" height="7.49996" rx="1" stroke="#83CDC8" stroke-width="2.4" mask="url(#path-3-inside-3_44_176)"/>
    <mask id="path-4-inside-4_44_176" fill="white">
    <rect y="9.49988" width="9.99994" height="12.9999" rx="1"/>
    </mask>
    <rect y="9.49988" width="9.99994" height="12.9999" rx="1" stroke="#83CDC8" stroke-width="2.4" mask="url(#path-4-inside-4_44_176)"/>
    </svg>,    
    component: AdminDashboard,
    path: "/fill-sites",
    layout: "/admin",
    isShow: "admin",
    role: "Operator || Manager",
    sub: true,
    children: [
      {
        name: "View All",
        path: "/fill-sites",
      },
      {
        name: "Add New",
        path: "/new-file-site",
      }
    ]
    // isCommon: true,
  },
  {
    name: "Contractors",
    iconSrc: Images.contractorUnactive,
    activeIconSrc: Images.contractor,
    icon:<img src={Images.contractorUnactive} alt="icon" style={{ width: '24px', height: '24px' }} />,
    component: AdminContractors,
    path: "/Admin-Contractors",
    layout: "/admin",
    isShow: "admin",
    sub: true,
    children: [
      {
        name: "View All",
        path: "/Admin-Contractors",
      },
      {
        name: "Account Requests",
        path: "/account-request",
      },
      {
        name: "Add New",
        path: "/new-contractors",
      }
    ]
    // role: "Operator || Manager"

    // isCommon: true,
  },
  {
    name: "Invoice",
    iconSrc: Images.myRequestsIcon,
    activeIconSrc: Images.myRequestsActiveIcon,
    component: Invoices,
    path: "/invoice",
    layout: "/admin",
    isShow: "admin",
    role: "Operator || Manager"
    // isCommon: true,
  },
  {
    name: "Manage Team",
    iconSrc: Images.teamMembersIcon,
    activeIconSrc: Images.teamMembersActiveIcon,
    icon:<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 19V17C17 15.9391 16.5786 14.9217 15.8284 14.1716C15.0783 13.4214 14.0609 13 13 13H5C3.93913 13 2.92172 13.4214 2.17157 14.1716C1.42143 14.9217 1 15.9391 1 17V19" stroke="#83CDC8" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 9C11.2091 9 13 7.20914 13 5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5C5 7.20914 6.79086 9 9 9Z" stroke="#83CDC8" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M23 19V17C22.9993 16.1137 22.7044 15.2528 22.1614 14.5523C21.6184 13.8519 20.8581 13.3516 20 13.13" stroke="#83CDC8" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 1.13C16.8604 1.35031 17.623 1.85071 18.1676 2.55232C18.7122 3.25392 19.0078 4.11683 19.0078 5.005C19.0078 5.89318 18.7122 6.75608 18.1676 7.45769C17.623 8.1593 16.8604 8.6597 16 8.88" stroke="#83CDC8" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/> </svg>,
    component: MyTeam,
    path: "/my-team",
    layout: "/admin",
    isShow: "admin",
    sub: true,
    children: [
      {
        name: "View All",
        path: "/my-team",
      },
      {
        name: "Add New",
        path: "/add-member",
      }
    ]
    // role:"Operator || Manager"
    // isCommon: true,
  },
  {
    name: "Documents",
    iconSrc: Images.DocumentAgreementActive,
    activeIconSrc: Images.DocumentAgreement,
    component: DocumentAgreement,
    path: "/Document-Agreement",
    layout: "/admin",
    isShow: "admin",
    // role: "Operator || Manager"

    // isCommon: true,
  },
  {
    name: "Contractor Detail",
    component: AdminContractorDetail,
    path: "/admin-contractor-detail",
    layout: "/admin",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Contractor Summary Detail",
    component: ContractorSummaryDetail,
    path: "/contractor-summary-detail",
    layout: "/admin",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Dashboard - Add New Soil Deposit Sites",
    component: EditAndViewUser,
    path: "/update-fill-site",
    layout: "/admin",
    isShow: false
    // isCommon: true,
  },

  {
    name: "Dashboard - Add New Soil Deposit Sites",
    component: NewFileSite,
    path: "/new-file-site",
    layout: "/admin",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Dashboard - Add New Soil Deposit Sites",
    component: NewContractors,
    path: "/new-contractors",
    layout: "/admin",
    isShow: false
    // isCommon: true,
  },
  // {
  //   name: "Contractors",
  //   iconSrc: Images.dashboardIcon,
  //   activeIconSrc: Images.dashboardActiveIcon,
  //   component: AdminDashboard,
  //   path: "/contractors",
  //   layout: "/admin",
  //   isShow: "admin"
  //   // isCommon: true,
  // },
  // {
  //   name: "Material Tracking",
  //   iconSrc: Images.dashboardIcon,
  //   activeIconSrc: Images.dashboardActiveIcon,
  //   component: AdminDashboard,
  //   path: "/dashboard",
  //   layout: "/admin",
  //   isShow: "admin"
  //   // isCommon: true,
  // },
  {
    name: "Material Tracking",
    iconSrc: Images.MaterialTracking,
    activeIconSrc: Images.MaterialTrackingactive,
    component: MaterialTracking,
    path: "/material_tracking",
    layout: "/admin",
    isShow: "admin",
    // isCommon: true,
  },
  {
    name: "Soil Authorization Form",
    component: ContractorSummaryDetail,
    path: "/soil-authorization-form",
    layout: "/admin",
    isShow: false
    // isCommon: true,
  },
  
  {
    name: "Contractor Account Requests",
    iconSrc: Images.AccountRequest,
    activeIconSrc: Images.AccountRequestActive,
    component: AccountRequest,
    path: "/account-request",
    layout: "/admin",
    isShow: "admin",
    role: "Operator || Manager"
  },
  
  // {
  //   name: "Invoices Information",
  //   iconSrc: Images.requestIcon,
  //   activeIconSrc: Images.requestActiveIcon,
  //   component: InvoicesInformation,
  //   path: "/invoicesInformation",
  //   layout: "/admin",
  //   isShow: "admin"
  // },
  {
    name: "Admin Invoice Details",

    component: AdminInvoiceDetails,
    path: "/admin-invoice-details",
    layout: "/admin",
    isShow: false,
    role: "Operator || Manager"
  },

  {
    name: "Soil Request Detail",
    component: SoilRequestDetail,
    path: "/soil-request-detail",
    layout: "/admin",
    isShow: false,
    role: "Operator || Manager"
  },
  {
    name: "Soil Request Confirm",
    component: SoilRequestConfirmPage,
    path: "/soil-request-confirm",
    layout: "/admin",
    isShow: false,
    role: "Operator || Manager"
  },
  {
    name: "Reject Soil Request",
    component: RejectRequestPage,
    path: "/soil-request-reject",
    layout: "/admin",
    isShow: false,
    role: "Operator || Manager"
  },
  
  // Team Members Subpage
  {
    name: "Add Team Member",
    component: AddTeamMember,
    path: "/add-member",
    layout: "/admin",
    isShow: false,
    // role:"Operator || Manager"
    // isCommon: true,
  },
  {
    name: "Team Member Profile",
    component: TeamMemberProfile,
    path: "/member-profile",
    layout: "/admin",
    isShow: false,
    // role:"Operator || Manager"
    // isCommon: true,
  },
  
   {
    name: "Site Reports",
    iconSrc: Images.reports,
    activeIconSrc: Images.activeReport,
    component: Reports,
    path: "/Reports",
    layout: "/admin",
    isShow: "admin",
    // role: "Operator || Manager"

    // isCommon: true,
  },
  {
    name: "Reports",
    // iconSrc: Images.DocumentAgreementActive,
    // activeIconSrc: Images. DocumentAgreement,
    component: ReportDetails,
    path: "/Report-Details",
    layout: "/admin",
    // isShow: "admin",
    // role: "Operator || Manager"

    // isCommon: true,
  },
  // auth Screen
  // {
  //   component: Landing,
  //   path: "/landing",
  //   layout: "/auth"
  // },
  {
    component: HomeScreen,
    path: "/account",
    layout: "/auth"
  },
  {
    component: SignUp,
    path: "/sign-up",
    layout: "/auth"
  },
  {
    component: LoginScreen,
    path: "/login",
    layout: "/auth"
  },
  {
    component: ChangePasword,
    path: "/changePasword",
    layout: "/auth"
  }
]
