import React from "react"
import AeSelect from "../../../../components/AeSelect"
import Input from "../../../../components/Input"
import closeIcon from '../../../../assets/images/close.svg'

const Materialneeded = props => {
  const { materialNeed, setMaterialNeed, onChangeText, materialNames, errors, componetFormRef,setSpecialMaterial,specialMaterial } = props

  const onRemoveItem = (index) => {
    let removeItem = materialNeed?.filter((_, idx) => idx !== index)
    setMaterialNeed(removeItem)
  }

  return (
    <>
      <form ref={componetFormRef}>
        {materialNeed &&
          materialNeed?.map((item, index) => (
            <div className="body-screen">
              <div className="close-icon text-end">
                <img src={closeIcon} onClick={() => onRemoveItem(index)} />
              </div>
              <div className="row expand">
                <div className="col-md-3 material-field">
                  <label className="label" style={{ marginBottom: "10px" }}>
                    Material Classification
                  </label>
                  <AeSelect
                    options={materialNames}
                    placeholder="Select Material"
                    value={item.material_name}
                    onChange={event => {
                      if(event === 8){
                        onChangeText('', index, "special_request")
                      }else{
                        onChangeText('none', index, "special_request")
                      }
                      onChangeText(event, index, "material_name")}}
                  />

                  {errors?.material_need && (
                    <label
                      className={`error-message ${errors?.material_need[index] &&
                        errors?.material_need[index]?.material_name
                        ? "opacity-1"
                        : ""
                        }`}
                    >
                      {errors?.material_need[index] &&
                        errors?.material_need[index].material_name}
                    </label>
                  )}
                </div>
                {/* BEGIN Material Type  */}
                <div className="col-md-2 material-field">
                  <label className="label">Material Type</label>
                  <label className="checkbox-wrapper">
                    <Input
                      type="radio"
                      name={`meterialtype[${index}]`}
                      className="checkbox-round"
                      value="1"
                      checked={item?.material_type === "1" ? "checked" : ""}
                      onChange={event => {
                        
                        onChangeText(event, index, "material_type")}}
                    />
                    <span>Structural</span>
                  </label>
                  <div className="error-name">
                    {errors?.material_need && (
                      <label
                        className={`error-message ${errors?.material_need[index] &&
                          errors?.material_need[index]?.material_type
                          ? "opacity-1"
                          : ""
                          }`}
                      >
                        {errors?.material_need[index] &&
                          errors?.material_need[index].material_type}
                      </label>
                    )}
                  </div>
                </div>

                <div
                  className="col-md-2 material-field "
                  style={{ marginTop: "27px" }}
                >
                  <div className="checkbox-wrapper">
                    <label className="check mt-1">
                      <Input
                        type="radio"
                        name={`meterialtype[${index}]`}
                        className="checkbox-round"
                        value="2"
                        onChange={event =>
                          onChangeText(event, index, "material_type")
                        }
                      />
                      <span>Non-structural</span>
                    </label>
                  </div>
                </div>
                {/* BEGIN Material mass  */}
                <div className="col-md-4 col-lg-3 material-field">
                  <label className="label">Material Amount</label>
                  <div className="">
                    <label className="checkbox-wrapper mt-4">
                      <Input
                        placeholder="00,00"
                        type="radio"
                        className="checkbox-round"
                        value="1"
                        name={`meterialamt[${index}]`}
                        onChange={event =>
                          onChangeText(event, index, "material_mass_type")
                        }
                      />
                      <span>Metric Tonnes (t)</span>
                    </label>
                    <label className="checkbox-wrapper checkbox-m ">
                      <Input
                        type="radio"
                        className="checkbox-round"
                        value="2"
                        name={`meterialamt[${index}]`}
                        onChange={event =>
                          onChangeText(event, index, "material_mass_type")
                        }
                      />
                      <span>
                        Cubic Meters (m3)
                      </span>
                    </label>
                  </div>
                  {/* <div
              className="error-name"
              
              > */}

                  {errors?.material_need && (
                    <label
                      className={`error-message ${errors?.material_need[index] &&
                        errors?.material_need[index]?.material_mass_type
                        ? "opacity-1"
                        : ""
                        }`}
                    >
                      {errors?.material_need[index] &&
                        errors?.material_need[index]?.material_mass_type}
                    </label>
                  )}

                  {/* </div> */}
                </div>
                <div className="col-md-3 col-lg-2 material-field inputfeild">
                  <div className="input-wrapper">
                    <Input
                      placeholder="00.00"
                      type="number"
                      value={item.material_amount}
                      showError={true}
                      errorMessage={
                        errors?.material_need &&
                        errors?.material_need[index]?.material_amount
                      }
                      onChange={event =>
                        onChangeText(event, index, "material_amount")
                      }
                    />
                  </div>
                </div>
              </div>
              {item.material_name == 8? <div className="row">
                <div className="col-md-4 col-lg-3 material-field " style={{marginTop:25}}>
                  <div className="input-wrapper">
                    <label className="label">Other Material Amount</label>
                    <Input
                      placeholder=""
                      type="text"
                      value={item.special_request}
                      showError={true}
                      errorMessage={
                        errors?.material_need &&
                        errors?.material_need[index]?.special_request
                      }
                      onChange={event =>
                        onChangeText(event, index, "special_request")
                      }
                    />
                  </div>
                </div>
              </div>:null}
            </div>
          ))}
      </form>
    </>
  )
}
export default Materialneeded
