import React, { useEffect, useState } from "react"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import useForm from "../../../utils/useForm"
import validator from "../../../utils/validation"
import { addProjectRequest, addProjectFailure } from "./redux/action"
import { connect } from "react-redux"
import "./style.scss"
import AesPhoneInput from "../../../components/PhoneInput"
import AeSelect from "../../../components/AeSelect"
import { toast } from "react-hot-toast"
import { Col, Collapse, DatePicker, Modal, Space, Select } from "antd"
import moment from 'moment';

const AddProjects = props => {
  const {
    addProjectRequest,
    addProjectFailure,
    projects,
    requesting,
    error,
    loginInfo
  } = props
  const [role, setRole] = useState("Operator")
  const stateSchema = {
    projectName: {
      value: "",
      error: ""
    },
    start_date: {
      value: "",
      error: ""
    },
    developer_name: {
      value: "",
      error: ""
    },
    address: {
      value: "",
      error: ""
    },
  }

  const validationStateSchema = {
    projectName: {
      required: true
    },
    start_date: {
      required: true
    },
    developer_name: {
      required: true
    },
    address: {
      required: true
    },
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    if (addProjectRequest) {
      setState(stateSchema)
      addProjectFailure(false)
    }
  }, [addProjectRequest])

  useEffect(() => {
    projects && history.push("projects")
  }, [projects])

  const submit = async () => {
    const formData = new FormData()
    formData.append("name", state.projectName.value)
    formData.append("start_date", moment(state.start_date.value).format('YYYY-MM-DD'))
    formData.append("developer_name", state.developer_name.value)
    formData.append("address", state.address.value)
    await addProjectRequest(formData)

    if (!state.projectName.value) {
      loginInfo?.user?.user_status &&
        toast.error("Mandatory fields are required")
    }
  }


  return (
    <>
      <HomeLayout dashboardHeader={true}>
        {loginInfo && loginInfo?.user?.role == "Operator" || loginInfo && loginInfo?.user?.role == "Manager" ? (
          <div className="errordiv">
            <p>You are Operator and not permitted</p>
          </div>
        ) :
          <section className="content-section add-team-members-section">
            <div className="add-team-members-content-section">
              <div className="heading-add-team-members">
                <div
                  className="back"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("projects")}
                >
                  <img src={backIcon} alt="" />
                </div>
                <h1>New Project</h1>
              </div>
              <div className="add-members-form-section">
                <div className="row">
                  <div className="col-md-6 add-member-field">
                    <Input
                      placeholder="Enter Project Name"
                      showLabel={true}
                      label="Project Name"
                      value={state.projectName.value}
                      showError={true}
                      errorMessage={state.projectName.error || error?.name}
                      onChange={val =>
                        handleOnChange("projectName", val.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-6 add-member-field">
                    <Input
                      placeholder="Enter Developer Name"
                      showLabel={true}
                      label="Developer Name"
                      value={state.developer_name.value}
                      showError={true}
                      errorMessage={state.developer_name.error || error?.name}
                      onChange={val =>
                        handleOnChange("developer_name", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 add-member-field">
                    <Input
                      placeholder="Enter Project Address"
                      showLabel={true}
                      label="Project Address"
                      value={state.address.value}
                      showError={true}
                      errorMessage={state.address.error || error?.name}
                      onChange={val =>
                        handleOnChange("address", val.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-6 add-member-field">
                    <p style={{fontWeight:'600',marginBottom:0}}>Project Start Date</p>
                    <DatePicker
                      style={{
                        width: "100%",
                        backgroundColor: "#fff",
                        height: "49px",
                        border: "unset"
                      }}
                      onChange={val =>
                        handleOnChange("start_date", val)
                      }
                      value={state.start_date.value}
                      
                      
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="button-wrapper">
                    <Button
                      showSpinner={requesting}
                      title="Create Project"
                      className="aes-btn green-btn"
                      onClick={() => submit()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => ({
  addProjectRequest: state.AddProject.addProjectRequest,
  requesting: state.AddProject.requesting,
  error: state.AddProject.error,
  projects: state.AddProject.projects,
  loginInfo: state.login.loginInfo,
})

const mapDispatchToProps = dispatch => ({
  addProjectRequest: data => dispatch(addProjectRequest(data)),
  addProjectFailure: data => dispatch(addProjectFailure(data))
  // signUpRequestSuccess: data => dispatch(signUpRequestSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddProjects)
