import React from "react"
import HomeLayout from "../../../layout/HomeLayout"
import "./style.scss"
import { Toaster } from "react-hot-toast"
import RequestListTable from "../../../components/RequestListTable"
import Button from "../../../components/Button"
import { history } from "../../../reduxStore/store"
import { connect } from "react-redux"
import { loginRequest } from "../../AuthScreens/LoginScreen/redux/action"

const RequestList = props => {
  const { loginRequest, loginInfo } = props
  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <Toaster position="top-center" />
        <section className="content-section team-members-section">
          <div className="team-members-content-section">
            <div className="heading-my-team">
              <h1>Soil Deposit Requests</h1>
              {loginInfo?.user?.role == 'Operator' ? '' :
                <Button
                  title="New Soil Deposit Request"
                  className="RequestSoilDeposit"
                  onClick={e => history.push("request-soil-deposit")}
                />}

            </div>
            <div className="team-members-table-wrapper">
              <RequestListTable />
            </div>
          </div>
        </section>
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo
})
export default connect(mapStateToProps)(RequestList)
