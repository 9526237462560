import React, { useEffect } from "react"
import { Space, Table } from "antd"
import moment from "moment"
import Button from "../Button"
import { history } from "../../reduxStore/store"
import "./style.scss"
import { connect } from "react-redux"
import { Spinner } from "react-bootstrap"
import { getTruckListRequest } from "../../pages/Contractor/TruckList/redux/action"
import { setSeeMoreID } from "../../pages/AuthScreens/LoginScreen/redux/action"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const TruckListTable = props => {
  const { getTruckListRequest, setSeeMoreID, myProjectsData, requesting } =
    props

  useEffect(() => {
    getTruckListRequest("")
  }, [])

  const loadingData = new Array(10).fill().map(() => ({ loading: true }))

  const columns = [
    {
      title: "P.O. Number",
      dataIndex: "p_o_number",
      key: "p_o_number",
      render: (_, { p_o_number, loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else {
          return (
            <Space size="middle" className="full-name">
              {p_o_number}
            </Space>
          )
        }
      }
    },
    {
      title: "Type of Truck",
      dataIndex: "type_of_truck",
      key: "type_of_truck",
      render: (_, { type_of_truck, loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else {
          return (
            <Space size="middle" className="full-name">
              {type_of_truck}
            </Space>
          )
        }
      }
    },
    {
      title: "License Plate No",
      dataIndex: "license_plate",
      key: "license_plate",
      render: (_, { license_plate, loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else {
          return (
            <Space size="middle" className="full-name">
              {license_plate}
            </Space>
          )
        }
      }
    },
    {
      title: "Number of Trips",
      dataIndex: "no_of_rounds",
      key: "no_of_rounds",
      render: (_, { no_of_rounds, loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else {
          return (
            <Space size="middle" className="email">
              {no_of_rounds}
            </Space>
          )
        }
      }
    }
  ]
  return (
    <>
      {/* {requesting ? (
        <div className="loader-container">
          <Spinner variant="primary" />
        </div>
      ) : ( */}
      <Table
        className="fill-site-table2"
        columns={columns}
        dataSource={myProjectsData?.length > 0 ? myProjectsData: []}
        pagination={false}
        rowKey="id"
        scroll={{ y: 560 }}
      />
      {/* )} */}
    </>
  )
}

const mapStateToProps = state => ({
  myProjectsData: state.Truck.projectsList,
  requesting: state.Truck.requesting
})

const mapDispatchToProps = dispatch => ({
  getTruckListRequest: data => dispatch(getTruckListRequest(data)),
  setSeeMoreID: data => dispatch(setSeeMoreID(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TruckListTable)
