import React, { useState, useEffect, useRef } from "react"
import "./style.scss"
import HomeLayout from "../../../layout/HomeLayout"
import plus from "../../../assets/images/plus.svg"
import pdfupload from "../../../assets/images/pdfupload.svg"
import uploadfile from "../../../assets/images/uploadfile.svg"
import Button from "../../../components/Button"
import { connect } from "react-redux"
import Input from "../../../components/Input"
import TextArea from "../../../components/TextArea"
import Materialneeded from "./components/Materialneeded"
import RequestSoilModal from "../../../constratarsModal/RequestSoilModal"
import { toast } from 'react-hot-toast'
import {
  soilDepositRequest,
  soilDepositRequestFailure,
  getMaterialRequest,
  getAdminProject
} from "./redux/action"
import { Select, Space } from "antd"
const RequestSoilDeposit = props => {
  const {
    soilDepositRequest,
    soilDepositRequestFailure,
    getMaterialRequest,
    getAdminProject,
    soilDepositData,
    materialNames,
    error,
    requesting,
    loginInfo,
    selectData,
  } = props

  const material = {
    material_name: "",
    material_type: "",
    material_mass_type: "",
    material_amount: ""
  }

  const [selectedFile, setSelectedFile] = useState(false)
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [materialOption, setMaterialOption] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [materialNeed, setMaterialNeed] = useState([material])
  const [specialMaterial, setSpecialMaterial] = useState("")
  const [materialType, setMaterialType] = useState("")
  const [laboratoryFileNumber, setLaboratoryFileNumber] = useState("")
  const [originatingLocation, setOriginatingLocation] = useState("")

  const [developerName, setDeveloperName] = useState("")
  const [projectStartDate, setProjectStartDate] = useState("")

  const [materialMassType, setMaterialMassType] = useState(false)
  const [materialAmount, setMaterialAmount] = useState("")
  const [note, setNote] = useState("")

  const [search, setSearch] = useState()

  const fileRef = useRef()
  const componetFormRef = useRef()
  useEffect(() => {
    getMaterialRequest()
    soilDepositRequestFailure(false)
  }, [])

  useEffect(() => {
    getAdminProject()
  }, [])



  useEffect(() => {
    materialNames.length > 0 && fillMeterialNames()
  }, [materialNames])

  const fillMeterialNames = () => {
    const materialOptions = []
    if (materialNames.length > 0) {
      materialNames.map(item =>
        materialOptions?.push({
          value: item.id,
          label: item.name
        })
      )
      setMaterialOption(materialOptions)
    }
  }
  // const options = materialNames?.map((items, index) => {
  //   return (
  //     <Option key={index} value={items.code}>
  //       {items.name}
  //     </Option>
  //   )
  // })

  // const handleChangeup = e => {
  //   setSelectedFile(e.target.files[0])
  //   setIsFilePicked(true)
  // }
  const handleChangeup = e => {
    const file = e.target.files[0]
    if (file.size > 50 * 1024 * 1024) {
      alert("File size exceeds 50 MB. Please select a smaller file.")
      setIsFilePicked(false)
    } else {
      setSelectedFile(file)
      setIsFilePicked(true)
    }
  }
  useEffect(() => {
    setModalShow(false)
  }, [])

  const onAddBtnClick = event => {
    event.preventDefault()
    setMaterialNeed([...materialNeed, material])
  }

  const handleChange = (e, index, name) => {
    const materials = [...materialNeed]
    materials[index][name] = e.target ? e.target.value : e
    setMaterialNeed(materials)
  }

  const handleProjectChange = (value) => {
    let filterProject = selectData.filter(data => data.id == value)
    console.log(filterProject[0]);
    setOriginatingLocation(filterProject[0].address)
    setDeveloperName(filterProject[0].developer_name)
    setProjectStartDate(filterProject[0].start_date)
    setSearch(value)

  }



  const clearData = () => {
    setModalShow(true)
    componetFormRef.current.reset()
  }

  useEffect(() => {
    if (soilDepositData.msg) {
      setMaterialMassType("")
      // setMaterialNeed([])
      setNote("")
      setSpecialMaterial("")
      setMaterialAmount("")
      // specialFormRef.current.reset()
      setSelectedFile(false)
      setIsFilePicked(false)

      setMaterialNeed([material])
      soilDepositRequestFailure(false)
      // setTimeout(() => {

      // }, 10)
    }
  }, [soilDepositData.msg])
  const [files, setFiles] = useState([])

  const handleFileChange = event => {
    const fileList = event.target.files
    // setSelectedFile([...fileList])
    const updatedFiles = [...files]

    for (let i = 0; i < fileList.length; i++) {
      updatedFiles.push(fileList[i])
    }

    setFiles(updatedFiles)

  }

  const handleFileDelete = index => {
    const updatedFiles = [...files]
    updatedFiles.splice(index, 1)
    setFiles(updatedFiles)

  }
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("material_need", JSON.stringify(materialNeed));
    formData.append("special_request", specialMaterial);
    formData.append("material_type", materialType);
    formData.append("material_mass_type", materialMassType);
    formData.append("material_amount", materialAmount);
    formData.append("note", note);
    formData.append("originating_location", originatingLocation);
    formData.append("laboratory_file", laboratoryFileNumber);
    formData.append("project", search);
    files.forEach((file, index) => {
      formData.append(`file_${index}`, file);
    });

    await soilDepositRequest(formData, clearData);

    const Material = materialNeed[0]

    if (
      !Material?.material_name ||
      !Material?.material_amount ||
      !Material?.material_type ||
      !Material?.material_mass_type ||
      !originatingLocation
    ) {
      loginInfo?.user?.user_status && toast.error("Mandatory fields are required")
    }
  };
  const { Option } = Select
  return (
    <>
      <HomeLayout dashboardHeader={true}>
        {loginInfo && loginInfo?.user?.role == "Operator" ? (
          <div className="errordiv">
            <p>You are Operator and not permitted</p>
          </div>
        ) : (
          <section className="content-section Material-section">
            <div className=" w-100 p-3" style={{ overflowX: "hidden" }}>
            <div className="headerText" style={{}}>
                <h1>Project Information</h1>
              </div>
              <div className="row " style={{ gap: 0,paddingTop:0 }}>
                <div className="col-md-6 selector-api">
                      <p style={{fontWeight:'600',marginBottom:0}}>Select Project:</p>
                      <Select
                        placeholder="Select project"
                        maxTagCount="responsive"
                        style={{
                          width: "100%",
                          height: 45
                        }}
                        value={search}
                        filterOption={(inputValue, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) !== -1
                        }
                        // label={adminData?.username}
                        onChange={handleProjectChange}
                      >
                        {selectData ? (
                          selectData.map(data => (
                            <Option
                              key={data.id}
                              value={data.id}
                              label={data.name}
                            >
                              <Space>{data.name}</Space>
                            </Option>
                          ))
                        ) : (
                          <Option value={null} disabled>
                            No data
                          </Option>
                        )}
                      </Select>
                </div>
                
                <div className="col-md-6">
                  <Input
                    placeholder="Enter originating location"
                    label="Originating Location:"
                    type="text"
                    showLabel={true}
                    showError={true}
                    onChange={e => setOriginatingLocation(e.target.value)}
                    errorMessage={error?.originating_location}
                    value={originatingLocation}
                  />
                </div>
                {developerName && <div className="col-md-6" style={{marginTop:10}}>
                  <Input
                    placeholder="Developer Name"
                    label="Developer Name:"
                    type="text"
                    showLabel={true}
                    showError={true}
                    onChange={e => setDeveloperName(e.target.value)}
                    errorMessage={error?.developer_name}
                    value={developerName}
                    disabled={true}
                  />
                </div>}
                {projectStartDate && <div className="col-md-6" style={{marginTop:10}}>
                  <Input
                    placeholder="Project Start Date"
                    label="Project Start Date:"
                    type="text"
                    showLabel={true}
                    showError={true}
                    onChange={e => setProjectStartDate(e.target.value)}
                    errorMessage={error?.start_date}
                    value={projectStartDate}
                    disabled={true}
                  />
                </div>}
              </div>
              <div className="mt-2 ">
                <label>Notes</label>
                <TextArea
                  placeholder="Type notes"
                  value={note}
                  onChange={e => setNote(e.target.value)}
                />
                <label
                  className={`error-message ${error && error?.note ? "opacity-1" : ""
                    }`}
                >
                  {error && error?.note ? error?.note : ""}
                </label>
              </div>
              <hr />
              <div className="headerText">
                <h1>Material Information</h1>
              </div>

              {/* body */}

              <div className="doteds">
                <Materialneeded
                  materialNeed={materialNeed}
                  setMaterialNeed={setMaterialNeed}
                  onChangeText={handleChange}
                  materialNames={materialOption}
                  errors={error}
                  componetFormRef={componetFormRef}
                  setSpecialMaterial={setSpecialMaterial}
                  specialMaterial={specialMaterial}
                />
              </div>
              
              <hr />
              <div className="fotter" style={{marginBottom:20}}>
                <h2>Supporting Documents</h2>
              </div>
              <div className="col-md-4" >
                  <Input
                    placeholder="Enter laboratory file number "
                    label="Laboratory File #: "
                    type="text"
                    showLabel={true}
                    showError={true}
                    onChange={e => setLaboratoryFileNumber(e.target.value)}
                    errorMessage={error?.laboratory_file}
                    value={laboratoryFileNumber}
                  />
                </div>
              <div className="upload-file-soil">
                <img
                  src={isFilePicked == false ? pdfupload : uploadfile}
                  alt="upload"
                />

                <div className="icon-wrapper d-flex " style={{ gap: "30px", }}>
                  <input
                    type="file"
                    name="file"
                    ref={fileRef}
                    accept=".pdf"
                    multiple
                    onChange={handleFileChange}
                    hidden
                  />
                  {/* {isFilePicked == false ? ( */}
                  <div onClick={() => fileRef.current.click()}>
                    <p>
                      <a href="#">Upload File</a>
                    </p>
                  </div>
                  {/* ) : ( */}
                  {/* <p>
                      <a
                        onClick={() => {
                          setSelectedFile(false)
                          setIsFilePicked(false)
                          fileRef.current.value = null
                        }}
                        href="#"
                      >
                        Remove File
                      </a>
                    </p>
                  )} */}
                </div>
              </div>
            </div>
            <div>
              <label
                className={`error-message  ${error && !selectedFile && error?.environmental
                  ? "opacity-1"
                  : ""
                  }`}
              >
                {!selectedFile && error?.environmental}
              </label>
              <div className="mx-1" >
                {files.map((file, index) => (
                  <div className="file-item mb-2" key={index}>
                    <p className="file-name">{file.name}</p>
                    <div
                      className="action"
                      onClick={() => handleFileDelete(index)}
                    >
                      {/* <img src={deleteICon} alt="del" /> */}
                      <p>

                        Remove
                      </p>
                    </div>
                    {/* <span
                      className="delete-icon"
                      onClick={() => handleFileDelete(index)}
                    >
                      X
                    </span> */}
                  </div>
                ))}
              </div>
              <div className="button-wrapper pb-4 mt-4">
                <Button
                  showSpinner={requesting}
                  title="Submit A Request"
                  className="aes-btn"
                  onClick={() => {
                    handleSubmit()
                  }}
                />
              </div>
            </div>
          </section>
        )}

        {modalShow && (
          <RequestSoilModal
            modalShow={modalShow}
            setModalShow={setModalShow}
            body={soilDepositData.msg}
            componetFormRef={componetFormRef}
          />
        )}
      </HomeLayout>
    </>
  )
}
const mapStateToProps = state => ({
  soilDepositData: state.soilDeposit.soilDepositdata,
  requesting: state.soilDeposit.requesting,
  error: state.soilDeposit.error,
  materialNames: state.soilDeposit.materialNameData,
  loginInfo: state.login.loginInfo,
  selectData: state.soilDeposit.getAdminProject
})
const mapDispatchToProps = dispatch => ({
    soilDepositRequest: (data, clearData) =>
    dispatch(soilDepositRequest(data, clearData)),
    getMaterialRequest: data => dispatch(getMaterialRequest(data)),
    soilDepositRequestFailure: data => dispatch(soilDepositRequestFailure(data)),
    getAdminProject: data => dispatch(getAdminProject(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(RequestSoilDeposit)
