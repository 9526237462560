import Header from "../../components/Header"
import React from "react"
import SidebarMenu from "../../components/SidebarMenu"

import "./style.scss"

const HomeLayout = props => {
  return (
    <section className="homepage-section">
      <SidebarMenu />
      <div className="main-section">
        <Header
         
          dashboardHeader={props.dashboardHeader}
          

        />
        {props.children}
      </div>
    </section>
  )
}

export default HomeLayout
