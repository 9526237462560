import React, { useEffect, useState } from "react"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import { Button, Dropdown, Menu, Table } from 'antd'
import "./style.scss"
import Search from "../../../components/Search"
import { connect } from "react-redux"
import { getAdminApprovedRequest } from "./redux/action"
import Skeleton from "react-loading-skeleton"
import filter from "../../../assets/images/filter.svg"

const SoilRequest = props => {
  const [status, setStatus] = useState("Select")
  const { getAdminApprovedRequest, AdminApprovedRequestdata, requesting } =
    props
  const [tabledata, setTabledata] = useState([])
  useEffect(() => {
    getAdminApprovedRequest("")
  }, [])
  const loadingData = new Array(10).fill().map(() => ({ loading: true }))

  const handleMenuClick = ({ key }) => {
    setStatus(key);
    getAdminApprovedRequest(`?status=${key}`)
  };

  useEffect(() => {
    if (AdminApprovedRequestdata && AdminApprovedRequestdata.length > 0) {
      const tableData = AdminApprovedRequestdata.map(item => ({
        key: item.id,
        aes_po_no: item.aes_po_no,
        company_name: item.company_name || "",
        material_type: item,
        status : item.status,
        material_amount: item,
        project_name: item.project_name
      }))
      setTabledata(tableData)
    } else {
      setTabledata([])
    }
  }, [AdminApprovedRequestdata])

  const columns = [
    {
      title: "Project Name",
      dataIndex: "project_name",
      key: "project_name",
      render: (text, { loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else {
          return (
            <div size="middle" className="full-name">
              {text}
            </div>
          )
        }
      }
    },
    {
      title: "M.E.N. PO#",
      dataIndex: "aes_po_no",
      key: "aes_po_no",
      render: (text, { loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else if (text) {
          return <div>{text}</div>
        } else {
          return <div>--</div>
        }
      }
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      render: (text, { loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else {
          return <div className="contractor-name">{text}</div>
        }
      }
    },

    {
      title: "Material Amount",
      key: "material_amount",
      render: (_, { material_amount, loading }) => {
        if (loading) {
          return <Skeleton width="50%" />
        } else if (material_amount?.deposit_request?.length) {
          // debugger
          return (
            <>
              {material_amount?.deposit_request
                .slice(0, 5)
                .map((request, index) => (
                  <span key={index}

                  >
                    {request.material_amount}


                    {request.material_mass_type === "1" ? (
                      "mt"
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{ __html: "m<sup>3</sup>" }}
                      />
                    )}
                    {index !== material_amount?.deposit_request.length - 1 &&
                      ", "}
                  </span>
                ))}
              {material_amount?.deposit_request.length > 6 && ". . ."}
            </>
          )
        } else {
          return <span>--</span>
        }
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, { loading }) => {
      
        if (loading) {
          return <Skeleton width="40%" />
        } else if (text) {
          return <div>{text == 'open'?'Pending':text}</div>
        } else {
          return <div>--</div>
        }
      }
    },
    {
      title: " ",
      key: "action",
      render: (_, { material_amount, loading }) => {
        if (loading) {
          return <Skeleton width="100%" />
        } else {
          return (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                title="View Request"
                className="aes-btn transparent-btn "
                onClick={() => {
                  const materialId = material_amount.id

                  localStorage.setItem("materialId", materialId)

                  const savedMaterialId = localStorage.getItem("materialId")
                  getAdminApprovedRequest(`${savedMaterialId}/`)

                  history.push("soil-request-detail")
                }}
              >
                View Request
              </Button>
            </div>
          )
        }
      }
    }
  ]
  let timeoutId
  const contractorSearch = e => {
    clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      getAdminApprovedRequest(`?name=${e.target.value}`)
    }, 1000)
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="">View All Requests</Menu.Item>
      <Menu.Item key="open">New/Pending Requests</Menu.Item>
      <Menu.Item key="accepted">Approved Requests</Menu.Item>
      <Menu.Item key="rejected">Declined Requests</Menu.Item>
    </Menu>
  )
  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="content-section soil-deposite-request-section">
          <div className="heading-request">
            <h1>Soil Deposit Requests</h1>
            <div style={{flexDirection:'row',display:'flex'}}>
              <form>
                <Search
                  placeholder="Search by contractor"
                  onChange={e => {
                    contractorSearch(e)
                  }}
                />
              </form>
              <div style={{marginLeft:10}}>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <div className="button-filter">
                    <img src={filter} />
                    <p>
                      Filter by status:
                      <span style={{ "text-transform": "capitalize" }}>{status}</span>
                    </p>
                  </div>
                </Dropdown>
              </div>
            </div>
            
          </div>

          <div className="soil-request-table-wrapper bodyScroll">
            <Table
              dataSource={requesting ? loadingData : tabledata}
              columns={columns}
              pagination={false}
              
            />
          </div>
        </section>
      </HomeLayout>
    </>
  )
}
const mapStateToProps = state => {
  return {
    AdminApprovedRequestdata: state.AdminSoilRequest.AdminApprovedRequestdata,
    requesting: state.AdminSoilRequest.requesting
  }
}

const mapDispatchToProps = dispatch => ({
  getAdminApprovedRequest: data => dispatch(getAdminApprovedRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SoilRequest)
