import React, { useEffect, useState } from "react"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import useForm from "../../../utils/useForm"
import validator from "../../../utils/validation"
import { addTruckRequest, addTruckFailure } from "./redux/action"
import { connect } from "react-redux"
import "./style.scss"
import AesPhoneInput from "../../../components/PhoneInput"
import AeSelect from "../../../components/AeSelect"
import { toast } from "react-hot-toast"
import TextArea from "../../../components/TextArea"
import { Col, Collapse, DatePicker, Modal, Space, Select } from "antd"
import { getScheduleListRequest } from "../../../pages/Contractor/ScheduleList/redux/action"
import { PlusCircleOutlined,CloseCircleOutlined } from '@ant-design/icons'


const AddTruck = props => {
  const {
    addTruckRequest,
    addTruckFailure,
    projects,
    requesting,
    error,
    loginInfo,
    myProjectsData,
    getScheduleListRequest
  } = props

  useEffect(() => {
    getScheduleListRequest("")
  }, [])


  const [role, setRole] = useState("Operator")
  const stateSchema = {
    companyName: {
      value: "",
      error: ""
    },
    driverName: {
      value: "",
      error: ""
    },
    licensePlate: {
      value: "",
      error: ""
    },
    configuration: {
      value: "",
      error: ""
    },
  }

  const validationStateSchema = {
    companyName: {
      required: true
    },
    driverName: {
      required: true
    },
    licensePlate: {
      required: true
    },
    configuration: {
      required: true
    }
  }

  const [search, setSearch] = useState()

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const [trucks, setTrucks] = useState([{
    typeOfTruck: "",
    licensePlate: "",
    noOfTrip: 1
  }]);

  useEffect(() => {
    if (addTruckRequest) {
      setState(stateSchema)
      addTruckFailure(false)
    }
  }, [addTruckRequest])

  useEffect(() => {
    projects && history.push("truck-details")
  }, [projects])

  const submit = async () => {
    if(!search){
      toast.error("Please select a Schedule Soil Deposits")
      return
    }else {
      let getValue = myProjectsData.find(data => data.id == search)
      let totalTrips = trucks.reduce((acc, truck) => acc + parseInt(truck.noOfTrip), 0)
      if(totalTrips > getValue.no_of_trip_left){
        toast.error("Number of trips exceeds the available trips")
        return
      }else{
        trucks.forEach(async (truck) => {
          const formData = new FormData();
          formData.append("type_of_truck", truck.typeOfTruck);
          formData.append("scheduled_soil_deposit_site", search);
          formData.append("license_plate", truck.licensePlate);
          formData.append("no_of_rounds", truck.noOfTrip);
          await addTruckRequest(formData);
        });
      }
    }
    /*const formData = new FormData()
    formData.append("company_name", state.companyName.value)
    formData.append("driver_name", state.driverName.value)
    formData.append("license_plate", state.licensePlate.value)
    formData.append("configuration", state.configuration.value)
    await addTruckRequest(formData)

    if (!state.companyName.value) {
      loginInfo?.user?.user_status &&
        toast.error("Mandatory fields are required")
    }
    if (!state.driverName.value) {
      loginInfo?.user?.user_status &&
        toast.error("Mandatory fields are required")
    }
    if (!state.licensePlate.value) {
      loginInfo?.user?.user_status &&
        toast.error("Mandatory fields are required")
    }
    if (!state.configuration.value) {
      loginInfo?.user?.user_status &&
        toast.error("Mandatory fields are required")
    }*/
  }
  const handleScheduleChange = (value) => {
    setSearch(value)
  }
  const { Option } = Select

  const addNewTruck = () => {
    setTrucks([...trucks, {
      typeOfTruck: "",
      licensePlate: "",
      noOfTrip: 1
    }]);
  };

  const handleTruckChange = (index, field, value) => {
    const newTrucks = [...trucks];
    newTrucks[index][field] = value;
    setTrucks(newTrucks);
  };

  const removeTruck = (index) => {
    setTrucks(trucks.filter((_, i) => i !== index));
  };
  return (
    <>
      <HomeLayout dashboardHeader={true}>
        {loginInfo && loginInfo?.user?.role == "Operator" || loginInfo && loginInfo?.user?.role == "Manager" ? (
          <div className="errordiv">
            <p>You are Operator and not permitted</p>
          </div>
        ) :
          <section className="content-section add-team-members-section">
            <div className="add-team-members-content-section">
              <div className="heading-add-team-members">
                <div
                  className="back"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("truck-details")}
                >
                  <img src={backIcon} alt="" />
                </div>
                <h1>New Dispatch</h1>
              </div>
              <div className="add-members-form-section">
                <div className="row">
                  <div className="col-md-12 selector-api" style={{marginBottom:10}}>
                      <p style={{fontWeight:'600',marginBottom:0}}>Schedule Soil Deposits:</p>
                      <Select
                        placeholder="Schedule Soil Deposits"
                        maxTagCount="responsive"
                        style={{
                          width: "100%",
                          height: 45
                        }}
                        value={search}
                        filterOption={(inputValue, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) !== -1
                        }
                        // label={adminData?.username}
                        onChange={handleScheduleChange}
                      >
                        {myProjectsData.length > 0 ? (
                          myProjectsData.map(data => (
                            <Option
                              key={data.id}
                              value={data.id}
                              label={data.approved_request_name+" - "+data.project_name}
                            >
                              <Space>{data.approved_request_name+" - "+data.project_name}</Space>
                            </Option>
                          ))
                        ) : (
                          <Option value={null} disabled>
                            No data
                          </Option>
                        )}
                      </Select>
                  </div>
                 </div>
                 {trucks.map((truck, index) => (
                    <div className="row" key={index}>
                  <div className="col-md-4 add-member-field">
                    <Input
                      placeholder="Enter Type of Truck"
                      showLabel={true}
                      label="Type of Truck"
                      value={truck.typeOfTruck}
                      showError={true}
                      onChange={(val) => handleTruckChange(index, "typeOfTruck", val.target.value)}
                    />
                  </div>
                  <div className="col-md-4 add-member-field">
                    <Input
                      placeholder="Enter License Plate No"
                      showLabel={true}
                      label="License Plate No"
                      value={truck.licensePlate}

                      onChange={(val) => handleTruckChange(index, "licensePlate", val.target.value)}
                    />
                  </div>
                  <div className="col-md-4 add-member-field" style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignContent:'center',alignItems:'center'}}>
                    <div style={{width:'90%'}}>
                    <Input
                      placeholder="Enter Number of Trips"
                      showLabel={true}
                      label="Number of Trips"
                      value={truck.noOfTrip}
                      onChange={(val) => handleTruckChange(index, "noOfTrip", val.target.value)}
                      type="number"
                      
                    />
                    </div>
                    {index == 0 ? <button 
                      style={{marginTop:20,border:'none',background:'none',color:'#000'}}
                      onClick={addNewTruck}
                    >
                      <PlusCircleOutlined />
                    </button> : <button 
                      style={{marginTop:20,border:'none',background:'none',color:'#000'}}
                      onClick={() => removeTruck(index)}
                    >
                      <CloseCircleOutlined />
                    </button>}
                  </div>
                </div>))}
                <div className="row">
                  <div className="col-md-12">
                    <div style={{flexDirection:'row',display:'flex',gap:10}}>
                      <Button
                          showSpinner={requesting}
                          title="Add Dispatch"
                          className="aes-btn green-btn"
                          onClick={() => submit()}
                        />
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => ({
  addTruckRequest: state.AddTruck.addTruckRequest,
  requesting: state.AddTruck.requesting,
  error: state.AddTruck.error,
  projects: state.AddTruck.projects,
  loginInfo: state.login.loginInfo,
  myProjectsData: state.Schedule.projectsList,
})

const mapDispatchToProps = dispatch => ({
  getScheduleListRequest: data => dispatch(getScheduleListRequest(data)),
  addTruckRequest: data => dispatch(addTruckRequest(data)),
  addTruckFailure: data => dispatch(addTruckFailure(data))
  // signUpRequestSuccess: data => dispatch(signUpRequestSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddTruck)
